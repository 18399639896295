<template lang="pug">
    section#acessoRapido
        ul
            li(v-for="acesso in acessos")
                a(:href="acesso.url")
                    .icon
                        img(:src="acesso.icon")
                    .conteudo
                        h3 {{acesso.title}}
                        p {{acesso.text}}
                        span Ir para página
        button(@click="scrollTo()").showMore
            FontAwesomeIcon(:icon="icons.faChevronDown")
</template>

<script>
import Link from '@components/Link/Link'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { props } from '@/mixins/component'

export default {
    name: "section-acesso-rapido",
    props,
    components: {
        Link,
    },
    data() {
        return {
            icons: {
                faChevronDown,
            },
            acessos: []
        }
    },
    methods: {
        scrollTo() {
            document.querySelector('#page').scrollTo({
                top: document.querySelector('#mosaico').offsetTop - 150,
                left: 0,
                behavior: 'smooth'
            })
        }
    },
    mounted() {
        this.acessos = this.contents.content.map(item => ({
            ...item,
            icon: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`,
        }))
    },
}
</script>

<style lang="stylus" scoped src="./QuickAccessSection.styl"></style>